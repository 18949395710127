<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Reservations.ModalEditExternalLink.Modal_Title')
    "
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveEditedLink"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped">
        <tbody>
          <tr>
            <td>Type</td>
            <td>
              <input
                type="text"
                v-model="mExternalLink.LinkType"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Link</td>
            <td>
              <input type="text" v-model="mExternalLink.Link" class="input" />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    externalLink: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mExternalLink: null,
    }
  },

  computed: {},

  created() {
    if (this.externalLink) {
      //console.log("whats link?", this.externalLink)
      this.mExternalLink = JSON.parse(JSON.stringify(this.externalLink))
    }
  },

  methods: {
    saveEditedLink(externalLink) {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        reservationProvider.methods
          .editExternalLink(self.mExternalLink)
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.isSavingSuccess = true

              setTimeout(() => {
                this.onClickCancel()
              }, 1500)

              this.$emit('externalLinkEdited', response.data)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
